import Chatbot from '@components/Chatbot';
import Matomo from '@components/Matomo';
import React from 'react';

const CustomScripts = () => {
  return (
    <>
      <Matomo />
      <Chatbot />
    </>
  );
};

export default CustomScripts;
