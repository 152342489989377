import Script from 'next/script';
import { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Image from 'next/image';
import clsx from 'clsx';

function Chatbot() {
  const [allowDsgvo, setAllowDsgvo] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      {!allowDsgvo ? (
        <Popover.Root open={open}>
          <Popover.Trigger asChild>
            <button
              className="gdpr-popover__trigger"
              aria-label="Popover Chatbot öffnen"
              onClick={() => setOpen(!open)}
            >
              <Image
                alt="logo Stadtwerke Witten"
                src="/stww-transparent.png"
                width={60}
                height={60}
                objectFit="contain"
              />
            </button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className={clsx('PopoverContent')}
              sideOffset={5}
              onInteractOutside={() => setOpen(false)}
            >
              <div className="gdpr-popover">
                Um unseren mehrsprachigen Chatbot verwenden zu können, müssen
                Sie die Weitergabe Ihrer Daten an unseren Anbieter erlauben
                (siehe{' '}
                <a href="/datenschutz" target="_blank" rel="noreferrer">
                  Datenschutzerklärung
                </a>
                )
                <div className="gdpr-popover__buttons">
                  <button className="button" onClick={() => setOpen(false)}>
                    Ablehnen
                  </button>
                  <button
                    className="button button--primary"
                    onClick={() => {
                      setOpen(false);
                      setAllowDsgvo(true);
                    }}
                  >
                    Erlauben
                  </button>
                </div>
              </div>
              <Popover.Arrow className="PopoverArrow" />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      ) : null}
      {allowDsgvo ? (
        <Script
          async
          defer
          strategy="lazyOnload"
          src={`https://stable.loyjoy.com/widget.js?process=${
            process.env.NEXT_PUBLIC_CHATBOT_CODE
          }${
            process.env.NEXT_PUBLIC_BASE_URL !==
            'https://schwalbe.stadtwerke-witten.de'
              ? '&loyjoy-open=true'
              : ''
          }`}
        />
      ) : null}
    </>
  );
}

export default Chatbot;
